.navbar-container {
    width: 100%;
    min-height: 60px;
    height: 60px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background: white;
    z-index: 100;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
    padding: 0px 20px;
}

.btn{
    margin-left: 10px;
    position: relative;
}


.title{
    font-weight: bolder;
    cursor: pointer;
}

.user-name{
    font-weight: bolder;
}

.btn-icon {
    width: 25px;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
}

.side-nav {
    background-color: white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 300px;
    height: 100vh;
    top: 0;
    right: -300px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
    animation: slide-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.side-nav.active {
    right: 0;
    animation: slide-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.side-nav-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #666;
    width: 100%;
    padding: 0px 20px;
}

.side-nav-title-container > .side-nav-title {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    min-height: 60px;
}

.side-nav-menu-container {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    border-bottom: 1px solid #666;
    width: 100%;
    flex-grow: 1;
}

.side-nav-menu-container > .side-nav-menu-button {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    border-bottom: 1px solid #ddd;
    width: 100%;
    padding: 10px 20px;
    cursor: pointer;
}

.side-nav-menu-container > .side-nav-menu-button:hover {
    background: #00000020;
}

.side-nav-menu-container > .noti-list{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    cursor: pointer;
}
.side-nav-menu-container > .noti-list:hover{
    background: #00000011;
}

.side-nav-menu-container > .noti-list > .noti-list-time{
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 12px;
    font-weight: bolder;
    color: darkblue;
}

.side-nav-menu-container > .noti-list > .noti-list-row{
    display: flex;
    align-items: center;
}

.side-nav-menu-container > .noti-list > .noti-list-row > .toppic{
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.side-nav-menu-container > .noti-list > .noti-list-row > .value{
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-weight: bolder;
}
.side-nav-menu-container > .noti-list > .noti-list-row > .value.danger{
    color: red;
}



.noti-pop{
    position: absolute;
    background: red;
    color: white;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50% ;
    width: 25px;
    height: 25px;
    top: -5px;
    right: -5px;
    animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) alternate both;
}

.remark-no-read{
    background: red;
    border-radius: 50% ;
    width: 15px;
    height: 15px;
    margin-right: 20px;
}

@keyframes slide-left {
    0% {
        right: -300px;
    }
    100% {
        right: 0px;
    }
}

@keyframes slide-right {
    0% {
        right: 0px;
    }
    100% {
        right: -300px;
    }
}

  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
